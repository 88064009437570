const lgNone = (...msg) => { }
const lgDebug = (true) ? console.debug : lgNone
const lgInfo = console.info
const lgWarn = console.warn
const lgError = console.error

export {
    lgDebug,
    lgInfo,
    lgWarn,
    lgError,
}